<template>
    <div class="im" v-if="roomId">
        <div class="im-box">
            <div class="im-box-header">{{ company }}正在为您服务</div>
            <div class="im-box-content">
                <div class="im-box-content-left">
                    <div ref="record" class="im-record" :style="!isShowMobile ? 'height: 60%;' : isShowTools ? `height: calc(100% - ${phoneHeight}px - 60px) !important;`: `height: calc(100% - ${phoneHeight}px - 10px) !important;`">
                        <div v-if="list.length !== 0" class="more" @click="getHistory(list[0].contentId)">
                            <el-divider content-position="center">{{
                                isShowMore ? '更多消息' : '暂无更多消息'
                            }}</el-divider>
                        </div>
                        <div v-for="(item, index) in list" :key="index">
                            <div v-if="item.who == 'user'" class="record-item-me">
                                <div class="record-item-me-name">我</div>
                                <span
                                    v-if="item.contentType == 'word'"
                                    class="record-item-me-text"
                                    style="white-space: pre-wrap;"
                                    v-html="item.content"
                                    ></span
                                >
                                <span v-else-if="item.contentType == 'img'" class="record-item-me-text">
                                    <img class="record-img" :src="item.content" alt="" />
                                </span>
                                <span v-else-if="item.contentType == 'vedio'" class="record-item-me-text">
                                    <video controls class="record-img" :src="item.content"></video>
                                </span>
                                <span v-else-if="item.contentType == 'audio'" class="record-item-me-text">
                                    <audio controls :src="item.content" class="record-img">
                                        您的浏览器不支持 audio 元素。
                                    </audio>
                                </span>
                                <span v-else class="record-item-me-text">
                                    <a target="_blank" :href="item.content">{{ item.content }}</a>
                                </span>
                            </div>
                            <div v-else class="record-item-other">
                                <div class="record-item-other-name">{{ item.serviceName || item.name }}</div>
                                <div v-if="item.type == 'welcome'">
                                    <span class="record-item-other-text" style="line-height: 25px;">
                                        <span>
                                            {{ item.welcome }}
                                        </span>                                       
                                    </span>
                                    <span class="record-item-other-text">
                                        <span class="guess">猜你想问</span>
                                        <span
                                            v-for="(item1, index1) in item.questions"
                                            :key="index1"
                                            class="questions"
                                            @click="clickSend(item1, 'word')"
                                        >
                                            {{ item1 }}
                                        </span>
                                    </span>
                                </div>
                                <div v-if="item.type == 'introduce'">
                                    <span class="record-item-other-text" style="line-height: 25px;">
                                        <span>
                                            {{ item.introduce }}
                                        </span>
                                        <br>
                                        <span v-if="item.mobile">
                                            电话：{{ item.mobile }}
                                            <span class="handle-text" v-if="!isShowMobile" @click="copy(item.mobile)">
                                                复制
                                            </span>
                                            <img v-else @click="callPhone(item.mobile)" src="@/assets/images/phone.png" class="phone-img" alt="">
                                        </span>
                                        <br>
                                        <span v-if="item.weixin">
                                            微信号：{{ item.weixin }}
                                            <span class="handle-text" @click="copy(item.weixin)">
                                                复制
                                            </span>
                                        </span>                                     
                                    </span>
                                </div>
                                <!-- <span class="record-item-other-text">
                                    <span v-html="item.welcome">
                                    </span>
                                    <span
                                        v-for="(item1, index1) in item.questions"
                                        :key="index1"
                                        class="questions"
                                        @click="clickSend(item1, 'word')"
                                    >
                                        {{ item1 }}
                                    </span>
                                </span> -->
                                <span
                                    v-if="item.contentType == 'word'"
                                    class="record-item-other-text"
                                    style="white-space: pre-wrap;"
                                    v-html="item.content"
                                    ></span
                                >
                                <span v-else-if="item.contentType == 'img'" class="record-item-other-text">
                                    <img class="record-img" :src="item.content" alt="" />
                                </span>
                                <span v-else-if="item.contentType == 'vedio'" class="record-item-other-text">
                                    <video controls class="record-img" :src="item.content"></video>
                                </span>
                                <span v-else-if="item.contentType == 'audio'" class="record-item-other-text">
                                    <audio controls :src="item.content" class="record-img">
                                        您的浏览器不支持 audio 元素。
                                    </audio>
                                </span>
                                <span
                                    v-else-if="item.contentType == 'file' && item.contentType == 'url'"
                                    class="record-item-other-text"
                                >
                                    <a target="_blank" :href="item.content">{{ item.content }}</a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="im-send">
                        <div class="im-send-tools">
                            <!-- <img src="../assets/biaoqing.png" class="im-send-tools-item" alt="" /> -->
                            <el-upload :show-file-list="false" action="" :http-request="upload" :limit="1">
                                <img src="../assets/images/tupian.png" class="im-send-tools-item" alt="" />
                            </el-upload>
                            <img @click="visible = true" src="../assets/images/liuyan.png" class="im-send-tools-item" alt="" />
                        </div>
                        <div class="im-send-content">
                            <el-input
                                v-model="send"
                                class="im-input"
                                type="textarea"
                                placeholder="请输入内容"
                                @keydown.ctrl.enter="handleEnter"
                                @keydown.enter.exact.native.prevent="clickSend(send, 'word')"
                            />
                        </div>
                        <div class="im-send-footer">
                            <div class="im-send-footer-btn" @click="clickSend(send, 'word')">发送</div>
                        </div>
                    </div>
                    <div class="im-send-phone" ref="phone">
                        <div style="flex: 1;margin-right: 10px;">
                            <el-input v-model="send" @input="changeValue" :autosize="{minRows: 1, maxRows: 3}" type="textarea" placeholder="请输入内容" />
                        </div>
                        <div class="im-send-phone-btn" @click="clickSend(send, 'word')">发送</div>
                        <img @click="isShowTools = !isShowTools" src="../assets/images/icon_new_add.png" class="im-send-phone-tools" alt="" srcset="">
                    </div>
                    <div v-if="isShowTools" class="im-send-phone-tool">
                        <el-upload :show-file-list="false" action="" :http-request="upload" :limit="1">
                            <img src="../assets/images/tupian.png" class="im-send-phone-tools" alt="" />
                        </el-upload>
                        <img style="margin-left: 10px;" @click="visible = true" src="../assets/images/liuyan.png" class="im-send-phone-tools" alt="" />
                    </div>
                </div>
                <!-- <div class="im-box-content-right"></div> -->
            </div>
        </div>
        <div class="support">
            佳鲸通提供客服软件支持
        </div>
        <el-dialog
            title="在线留言"
            :visible.sync="visible"
            :width="width"
            :before-close="handleClose">
            <el-form label-position="left" ref="form" :model="form" label-width="80px">
                <el-form-item label="您的姓名">
                    <el-input v-model="form.userName" />
                </el-form-item>
                <el-form-item label="电话号码">
                    <el-input v-model="form.mobile" />
                </el-form-item>
                <el-form-item label="留言内容">
                    <el-input type="textarea" v-model="form.content" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="addLeave">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="consultVisible"
            :width="width"
            :before-close="handleConsultClose">
            <div>
                您已在其他页面发起咨询，当前咨询已失效，<span class="re-consult" @click="reloadConsult">请点击这里重新咨询</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="consultVisible = false">取 消</el-button>
                <el-button type="primary" @click="reloadConsult">重新发起咨询</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
    data() {
        return {
            visible:false,
            send: '',
            roomId: '',
            list: [],
            timeout: 1000 * 4,
            timeoutObj: null,
            serverTimeoutObj: null,
            lockReconnect: false,
            tt: null,
            sessionId: null,
            isShowMore: true,
            customerCode: '',
            customerName: '',
            company: '',
            isShowTools: false,
            form: {
                app:'',
                content:'',
                mobile: '',
                userName: ''
            },
            width:'40%',
            tt:null,
            consultVisible: false,
            isShowMobile: null,
            phoneHeight:'50'
        }
    },
    async mounted() {
        this.isShowMobile = this.isMobile()
        this.customerCode = this.$route.query.customerCode
        if(localStorage.getItem('customerCode') != this.customerCode) {
            localStorage.removeItem('customerCode')
            localStorage.removeItem('roomId')
        }
        this.customerName = this.$route.query.customerName
        this.app = this.$route.query.app
        this.send = this.$route.query.target ? this.$route.query.target : ''
        this.company = this.$route.query.appName
        this.list = []
        await this.getHistory()
        if(localStorage.getItem('roomId')) {
            this.roomId = localStorage.getItem('roomId')
        }
        this.initWebSocket(this.createWs)
        if(this.isMobile()) {
            this.width = '80%'
        }
        this.readMessage()
    },
    beforeDestroy() {
        this.tt && clearInterval(this.tt);
    },
    methods: {
        readMessage() {
            request
            .post("/api/ws/read", { customerCode: this.customerCode, app:this.app,time:new Date(),who:'user' })
        },
        changeValue() {
            const myElement = this.$refs.phone;
            console.log(myElement.offsetHeight);
            this.phoneHeight = myElement.offsetHeight;
            console.log(this.phoneHeight);
        },
        isMobile() {
            return /Android|iPhone|iPad|iPod|Windows Phone|Mobile/i.test(navigator.userAgent);
        },
        callPhone(e) {
            const a = document.createElement('a');  
            a.href = `tel:${e}`;  
            a.target = '_blank'; // 在新窗口或标签页打开（虽然对于电话链接来说可能没用）  
            a.style.display = 'none'; // 隐藏元素  
            document.body.appendChild(a); // 将元素添加到 body 中  
            a.click(); // 模拟点击  
            document.body.removeChild(a); // 然后移除元素 
        },
        copy(e) {
            if(document.queryCommandSupported('copy')) {
                let textarea = document.createElement("textarea")
                textarea.value = e
                textarea.readOnly = "readOnly"
                document.body.appendChild(textarea)
                textarea.select() // 选中文本内容
                textarea.setSelectionRange(0, e.length) 
                let result = document.execCommand("copy") 
                textarea.remove()
                this.$message.success("复制成功");
            }

        },

        reloadConsult() {
            window.location.reload()
        },
        handleConsultClose() {
            this.consultVisible = false
        },
        isMobile() {
            return /Android|iPhone|iPad|iPod|Windows Phone|Mobile/i.test(navigator.userAgent);
        },
        addLeave() {
            this.form.app = this.app
            console.log(this.form);
            request.post('/api/leaveMessage/saveInfo',this.form).then(res => {
                this.$message.success('留言成功')
                this.visible = false
            })
        },
        handleClose() {
            this.visible = false
        },
        handleEnter(event) {
            if (event.shiftKey && event.code === 'Enter') {
                this.send += '\n' // 在输入内容后面添加换行符号
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const element = this.$refs.record
                element.scrollTop = element.scrollHeight
            })
        },
        upload(a) {
            console.log(a)
            const file = a.file
            const isAudio = file.type.startsWith('audio/')
            const isVideo = file.type.startsWith('video/')
            const isImage = file.type.startsWith('image/')
            const isDocument = !isAudio && !isVideo && !isImage
            let contentType
            if (isAudio) {
                contentType = 'audio'
            } else if (isVideo) {
                contentType = 'vedio'
            } else if (isImage) {
                contentType = 'img'
            } else if (isDocument) {
                contentType = 'file'
            }
            const form = new FormData()
            form.append('displayName', file.name)
            form.append('busiType', 'talkMedia')
            form.append('file', file)
            form.append('app',this.app)
            request.post('/common/file/upload', form, { 'Content-Type': 'multipart/form-data' }).then((res) => {
                this.clickSend(res.fileUrl, contentType)
            })
        },
        getHistory(contentId) {
            request.get('/api/ws/history', { customerCode: this.customerCode, num: 10, contentId,app:this.app }).then((res) => {
                if (res.length < 10) {
                    this.isShowMore = false
                }
                res.reverse()
                this.list.unshift(...res)
                if (this.list.length <= 10) {
                    this.scrollToBottom()
                }
            })
        },
        isURL(text) {
            const urlPattern = new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$',
                'i'
            ) // fragment locator
            return urlPattern.test(text)
        },
        clickSend(content, contentType) {
            if (contentType == 'word') {
                if (this.isURL(content)) {
                    contentType = 'url'
                }
            }
            request.post('/api/ws/dialogue', { content, contentType, roomId: this.roomId, who: 'user',customerCode:this.customerCode,app:this.app }).then((res) => {
                this.list.push({
                    content,
                    contentType,
                    createAt: new Date().getTime(),
                    contentId: res,
                    who: 'user'
                })
                console.log('发送的内容' + content);
                this.send = ''
                this.scrollToBottom()
            })
        },
        createWs() {
            request
                .post('/api/ws/createSession', {
                    customerCode: this.customerCode,
                    customerName: this.customerName,
                    sessionId: this.sessionId,
                    app:this.app,
                    roomId: this.roomId
                })
                .then((res) => {
                    this.roomId = res
                    localStorage.setItem('roomId',res)
                    localStorage.setItem('customerCode',this.customerCode)
                })
        },
        reloadSession() {
            request.post('/api/ws/reloadSession', { roomId: this.roomId, sessionId: this.sessionId,customerCode: this.customerCode,app:this.app }).then((res) => {})
        },
        initWebSocket(fn) {
            const that = this
            this.ws = new WebSocket(this.$wsUrl)
            this.ws.onmessage = function(event) {
                that.tt && clearInterval(that.tt)
                const data = JSON.parse(event.data)
                if (data.type == 'session') {
                    that.sessionId = data.data
                    fn()
                }
                if (data.type == 'content' || data.type == 'welcome' || data.type == 'introduce') {
                    data.data.who = 'service'
                    data.data.type = data.type
                    that.list.push(data.data)
                    that.scrollToBottom()
                    that.readMessage()
                    console.log(that.list);
                }
                if(data.type == 'newConnect') {
                    that.consultVisible = true
                    that.ws.close()
                }
                console.log(`收到消息：${event.data}`)
            }
            this.ws.onopen = this.websocketOpen
            this.ws.onerror = this.websocketError
            this.ws.onclose = this.websocketClose
        },
        websocketOpen() {
            // this.createWs()
            console.log('websocket onopen', this.ws.readyState)
            // this.heartCheckStart()
        },
        websocketClose() {
            console.log('websocket onClose', this.ws.readyState)
            if(!this.consultVisible) {
                this.reconnect()
            }
        },
        websocketError() {
            console.log('websocket onerror', this.ws.readyState)
            if(!this.consultVisible) {
                this.reconnect()
            }
        },
        sendMessage() {
            if (this.ws.readyState === WebSocket.OPEN) {
                this.ws.send('发送一条消息')
            }
        },
        heartCheckStart() {
            console.log('开始心跳检测')
            const self = this
            this.timeoutObj && clearTimeout(this.timeoutObj)
            this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj)
            this.timeoutObj = setTimeout(() => {
                // 这里发送一个心跳，后端收到后，返回一个心跳消息，
                // onmessage拿到返回的心跳就说明连接正常
                console.log('心跳检测...')
                self.ws.send('HeartBeat:' + self.roomId)
                self.serverTimeoutObj = setTimeout(() => {
                    if (self.ws.readyState != 1) {
                        self.ws.close()
                    }
                    // createWebSocket();
                }, self.timeout)
            }, this.timeout)
        },
        reconnect() {
            if (this.lockReconnect) {
                return
            }
            this.lockReconnect = true
            const that = this
            // 没连接上会一直重连，设置延迟避免请求过多
            this.tt && clearInterval(this.tt)
            this.tt = setInterval(function() {
                console.log('执行断线重连...')
                that.initWebSocket(that.reloadSession)
                that.lockReconnect = false
            }, 4000)
        }
    }
}
</script>

<style lang="scss" scoped>
// @import '../style/global.scss';
.im {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    letter-spacing: 2px;
    background: #F1F2F3;
    .im-box {
        background: #fff;
        // border-radius: 10px;
        overflow: hidden;
        height: 100%;
        width: 80%;
        max-width: 900px;
        &-header {
            font-size: 18px;
            padding: 0 20px;
            line-height: 60px;
            height: 60px;
            background-color: #43b4ea;
            color: #fff;
        }
        &-content {
            display: flex;
            height: calc(100% - 60px);
            &-left {
                font-size: 15px;
                flex: 1;
                border-right: 1px solid #e6e6e6;
                height: 100%;
                .im-record {
                    box-sizing: border-box;
                    height: 60%;
                    border-bottom: 1px solid #e6e6e6;
                    overflow-y: scroll;
                    padding: 7px 12px;
                    // background-color: #f1f2f3;
                    .more {
                        margin-bottom: 10px;
                        cursor: pointer;
                        ::v-deep .el-divider__text {
                            color: #999;
                        }
                    }
                    .record-item-me {
                        width: auto;
                        box-sizing: content-box;
                        margin-left: auto;
                        margin-bottom: 10px;
                        max-width: 50%;
                        text-align: right;
                    }
                    .record-item-other {
                        max-width: 50%;
                        margin-bottom: 10px;
                    }
                    .record-item-other-name,
                    .record-item-me-name {
                        margin-bottom: 3px;
                        color: #999;
                        font-size: 12px;
                    }
                    .record-item-other-text {
                        margin-bottom: 10px;
                        display: inline-block;
                        background-color: #f3f4f7;
                        padding: 5px;
                        border-radius: 0 5px 5px 5px;
                        letter-spacing: 2px;
                    }
                    .record-item-me-text {
                        letter-spacing: 2px;
                        display: inline-block;
                        background-color: #6493fc;
                        color: #fff;
                        padding: 5px;
                        border-radius: 5px 0 5px 5px;
                    }
                    .record-img {
                        max-width: 100%;
                    }
                }
                .im-send {
                    height: 40%;
                    position: relative;
                    ::v-deep .im-input {
                        height: 100%;
                    }
                    &-tools {
                        display: flex;
                        padding: 5px;
                        &-item {
                            margin-right: 10px;
                            width: 32px;
                            height: 32px;
                        }
                    }
                    &-content {
                        height: calc(100% - 90px);
                        ::v-deep .im-input {
                            height: 100%;
                        }
                        ::v-deep textarea {
                            height: 100%;
                            border: none;
                            outline: none; /*边线不显示*/
                            resize: none; /*禁止拉伸*/
                            appearance: none;
                        }
                        
                    }
                    &-footer {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        &-btn {
                            line-height: 30px;
                            text-align: center;
                            width: 50px;
                            height: 30px;
                            border-radius: 5px;
                            background-color: #43b4ea;
                            color: #fff;
                            cursor: pointer;
                        }
                    }
                }
            }
            &-right {
                width: 20%;
            }
        }
        
    }
    ::-webkit-scrollbar {
        display: none;
    }
    ::v-deep .el-divider--horizontal {
        margin: 6px 0;
    }
    @media screen and (max-width:600px) {
        .im-box {
            width: 100%;
        }
        .im-send {
            display: none;
        }
        .im-record {
            height: calc(100% - 50px) !important;
        }
        .im-send-phone {
            background-color: #fff;
            // height: 50px;
            display: flex;
            padding: 8px 10px;
            align-items: center;
            box-sizing: border-box;
            ::v-deep .el-input__inner {
                background-color: #F2F3F6;
                font-size: 17px !important;
            }
            &-btn {
                line-height: 32px;
                text-align: center;
                width: 50px;
                height: 32px;
                border-radius: 5px;
                background-color: #43b4ea;
                color: #fff;
                cursor: pointer;
                margin-right: 10px;
            }
            &-tools {
                width: 32px;
                height: 32px;
            }
            &-tool {
                padding: 10px;
                box-sizing: border-box;
                height: 50px;
                display: flex;
                align-items: center;
            }
        }
        .record-item-other {
            max-width: calc(100% - 40px) !important;
        }
        .record-item-me {
            max-width: calc(100% - 40px) !important;
        }
        
    }
}
.re-consult {
    color: #6493fc;
    cursor: pointer;
}
.questions {
    color: blue;
    display: block;
    cursor: pointer;
    margin-top: 10px;
}
.questions:hover {
    color: green;
}
.guess {
    font-weight: bold;
    font-size: 16px;
}
.handle-text {
    color: green;
    cursor: pointer;
}
.phone-img {
    width: 16px;
    height: 16px;
}
.support {
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #999;
  font-size: 12px;
}
</style>
